export function simpleRadio(){











}
