// $radios= document.querySelectorAll('.radio')
//
// $radios.forEach(function($radio) {
//     // Добавьте обработчик события клика для блока тарифа
//     $radio.addEventListener('click', function() {
//         // Найдите радио кнопку внутри блока тарифа
//         let radioInput = $radio.querySelector('.tariff-radio');
//         console.log(radioInput)
//         console.log('CHECK')
//
//
//         // Проверьте, не выбрана ли уже эта радио кнопка
//         if (!radioInput.checked) {
//             console.log('change')
//             console.log(radioInput)
//             // Если не выбрана, выберите её
//             radioInput.checked = true;
//         }
//     });
// });
