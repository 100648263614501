export function formatCardMonthToInterface(card) {

    let cardLength = card.length - 4;

    if (cardLength < 0) {
        cardLength = 0;
    }

    let up = card.substring(cardLength);

    const n0 = up[0] ?
        up[0] :
        '';
    const n1 = up[1] ?
        up[1] :
        '';
    const n2 = up[2] ?
        up[2] :
        '';
    const n3 = up[3] ?
        up[3] :
        '';


    if (n2 == '' && n3 == ''){
        let number = `${n0}${n1}`;
        return number;
    }

    let number = `${n0}${n1}/${n2}${n3}`;

    return number;

}
