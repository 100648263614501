export function formatCardNumberToInterface(card) {

    let cardLength = card.length - 16;

    if (cardLength < 0) {
        cardLength = 0;
    }

    let up = card.substring(cardLength);

    const n0 = up[0] ?
        up[0] :
        '';
    const n1 = up[1] ?
        up[1] :
        '';
    const n2 = up[2] ?
        up[2] :
        '';
    const n3 = up[3] ?
        up[3] :
        '';
    const n4 = up[4] ?
        up[4] :
        '';
    const n5 = up[5] ?
        up[5] :
        '';
    const n6 = up[6] ?
        up[6] :
        '';
    const n7 = up[7] ?
        up[7] :
        '';
    const n8 = up[8] ?
        up[8] :
        '';
    const n9 = up[9] ?
        up[9] :
        '';
    const n10 = up[10] ?
        up[10] :
        '';
    const n11 = up[11] ?
        up[11] :
        '';
    const n12 = up[12] ?
        up[12] :
        '';
    const n13 = up[13] ?
        up[13] :
        '';
    const n14 = up[14] ?
        up[14] :
        '';
    const n15 = up[15] ?
        up[15] :
        '';

    if (n4 == '' && n5 == ''){
        let number = `${n0}${n1}${n2}${n3}`;
        return number;
    }
    if (n8 == '' && n9 == ''){
        let number = `${n0}${n1}${n2}${n3} ${n4}${n5}${n6}${n7}`;
        return number;
    }
    if (n12 == '' && n13 == ''){
        let number = `${n0}${n1}${n2}${n3} ${n4}${n5}${n6}${n7} ${n8}${n9}${n10}${n11}`;
        return number;
    }


    let number = `${n0}${n1}${n2}${n3} ${n4}${n5}${n6}${n7} ${n8}${n9}${n10}${n11} ${n12}${n13}${n14}${n15}`;

    return number;

}
