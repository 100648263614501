import calcOptionsPrice from "../../../utilities/calcOptionsPrice/calcOptionsPrice";
import {DateTime} from 'luxon';
import calcRentalDuration from "../../../utilities/calcRentalDuration/calcRentalDuration";

export function orderSummary({$wrapper}) {
    const classPrefix = 'order-summary'
    const dataPrefix = 'data-order-summary'

    const $template = document.querySelector(`[${dataPrefix}-template]`)
    const $rowTemplate = document.querySelector(`[${dataPrefix}-row-template]`)
    const $itemTemplate = document.querySelector(`[${dataPrefix}-item-template]`)

    let rent_price = null;
    let rent_total_price = null;
    let rent_days = null;
    let rent_end = null;
    let rent_start = null;
    let take_price = null;
    let return_price = null;
    let take_time_price = null;
    let return_time_price = null;
    let options_price = null;

    let option_buster = null;
    let option_buster_price = null;
    let option_childseat = null;
    let option_childseat_price = null;
    let option_phoneholder = null;
    let option_phoneholder_price = null;


    let $items = null
    let $mobileStartDate = null
    let $mobileStartPlace = null
    let $mobileEndDate = null
    let $mobileEndPlace = null
    let $mobileResult = null

    init()

    function init() {
        const $clone = document.importNode($template.content, true)
        $items = $clone.querySelector(`[${dataPrefix}-items]`)
        $mobileStartDate = $clone.querySelector(`[${dataPrefix}-mobile-start-date]`)
        $mobileStartPlace = $clone.querySelector(`[${dataPrefix}-mobile-start-place]`)
        $mobileEndDate = $clone.querySelector(`[${dataPrefix}-mobile-end-date]`)
        $mobileEndPlace = $clone.querySelector(`[${dataPrefix}-mobile-end-place]`)
        $mobileResult = $clone.querySelector(`[${dataPrefix}-mobile-result]`)

        $wrapper.appendChild($clone)
    }

    function setData(orderData) {

        $items.innerHTML = ''

        if (!orderData.endTime) {
            return
        }

        const startTime = orderData.startTime
        const endTime = orderData.endTime

        const startDate = {date: orderData.startDate, time: startTime};
        const endDate = {date: orderData.endDate, time: endTime};

        const duration = calcRentalDuration(startDate, endDate)

        const ratePricesUnsorted = orderData.rate.rate_prices
        const rateTimePaysUnsorted = orderData.rate.rate_time_pays

        const ratePrices = ratePricesUnsorted.sort((a, b) => {
            return a.min_days - b.min_days
        })
        const rateTimePays = rateTimePaysUnsorted.sort((a, b) => {
            return a.from_min - b.from_min
        })

        const countDaysToGetRate = getCountDaysToGetRate(rateTimePays, duration)

        const dayRate = getDayRate(ratePrices, countDaysToGetRate)
        const amount = calcAmount(dayRate, duration, orderData.rate.rate_time_pays)
        const optionsPrice = calcOptionsPrice({
            busterCount: orderData.orderOptions.option_buster ? +orderData.orderOptions.option_buster : 0,
            phoneholderCount: 0,
            childseatCount: orderData.orderOptions.option_childseat ? +orderData.orderOptions.option_childseat : 0,
            durationInDays: countDaysToGetRate,
        })

        const fullAmount = amount + optionsPrice.options_price

        const timeIntervals = [
            // {start: "18:01", end: "20:00", price: 500},
            // {start: "20:01", end: "22:00", price: 1000},
            // {start: "22:01", end: "06:00", price: 1500},
            // {start: "06:01", end: "07:30", price: 1000},
            // {start: "07:31", end: "08:59", price: 500}
            {start: "18:01", end: "20:00", price: 1000},
            {start: "20:01", end: "22:00", price: 1000},
            {start: "22:01", end: "06:00", price: 1000},
            {start: "06:01", end: "07:30", price: 1000},
            {start: "07:31", end: "08:59", price: 1000}
        ];

        const payStartOnUnWorkTime = getPriceForTime(startTime, timeIntervals);
        const payEndOnUnWorkTime = getPriceForTime(endTime, timeIntervals);
        const payStartFromOtherPlace = orderData.startDelivery == 3 ? 1000 : 0;
        const payEndFromOtherPlace = orderData.endDelivery == 3 ? 1000 : 0;

        const $datesRowClone = document.importNode($rowTemplate.content, true)
        const $placesRowClone = document.importNode($rowTemplate.content, true)
        const $timeRowClone = document.importNode($rowTemplate.content, true)
        const $summaryRowClone = document.importNode($rowTemplate.content, true)


        addItem({
            $wrapper: $datesRowClone,
            title: 'Начало проката',
            value: `${convertDate(orderData.startDate)} ${startTime}`
        })

        addItem({
            $wrapper: $datesRowClone,
            title: 'Конец проката',
            value: `${convertDate(orderData.endDate)} ${endTime}`
        })

        addItem({
            $wrapper: $datesRowClone,
            title: 'Дней проката',
            value: `${duration.days > 0 ? duration.days : 1} ${declineDays(duration.days > 0 ? duration.days : 1)}`
        })

        addItem({
            $wrapper: $placesRowClone,
            title: 'Получение вне офиса',
            value: `${payStartFromOtherPlace} руб.`
        })

        addItem({
            $wrapper: $placesRowClone,
            title: 'Возврат вне офиса',
            value: `${payEndFromOtherPlace} руб.`
        })

        addItem({
            $wrapper: $timeRowClone,
            title: 'Получение в нерабочее время',
            value: `${payStartOnUnWorkTime} руб.`
        })

        addItem({
            $wrapper: $timeRowClone,
            title: 'Возврат в нерабочее время',
            value: `${payEndOnUnWorkTime} руб.`
        })

        addItem({
            $wrapper: $summaryRowClone,
            title: 'Цена проката',
            value: `${amount} руб.`
        })

        addItem({
            $wrapper: $summaryRowClone,
            title: 'Доп опции',
            value: `${optionsPrice.options_price} руб.`
        })

        addItem({
            $wrapper: $summaryRowClone,
            title: 'Итого',
            value: `${fullAmount + payStartFromOtherPlace + payEndFromOtherPlace + payStartOnUnWorkTime + payEndOnUnWorkTime} руб.`
        })

        rent_price = amount;
        options_price = optionsPrice.options_price;
        rent_total_price = amount + options_price + payStartFromOtherPlace + payEndFromOtherPlace + payStartOnUnWorkTime + payEndOnUnWorkTime;
        option_buster = optionsPrice.option_buster;
        option_buster_price = optionsPrice.option_buster_price;
        option_childseat = optionsPrice.option_childseat;
        option_childseat_price = optionsPrice.option_childseat_price;
        option_phoneholder = optionsPrice.option_phoneholder;
        option_phoneholder_price = optionsPrice.option_phoneholder_price;
        rent_days = duration.days > 0 ? duration.days : 1;
        rent_end = `${convertDate(orderData.endDate)} ${endTime}`;
        rent_start = `${convertDate(orderData.startDate)} ${startTime}`;
        take_price = payStartFromOtherPlace;
        return_price = payEndFromOtherPlace;
        take_time_price = payStartOnUnWorkTime;
        return_time_price = payEndOnUnWorkTime;


        let startPlace = '-';

        if (orderData.startDelivery) {
            if (orderData.startDelivery === 'other') {
                startPlace = orderData.startOtherDelivery
            } else {
                startPlace = window.orderDeliveries.find(item => +item.id === +orderData.startDelivery).name
            }
        }

        let endPlace = '-';

        if (orderData.endDelivery) {
            if (orderData.endDelivery === 'other') {
                endPlace = orderData.endOtherDelivery
            } else {
                endPlace = window.orderDeliveries.find(item => +item.id === +orderData.endDelivery).name
            }
        }

        $mobileStartDate.innerText = `${convertDate(orderData.startDate)} ${startTime}`
        $mobileStartPlace.innerText = startPlace
        $mobileEndDate.innerText = `${convertDate(orderData.endDate)} ${endTime}`
        $mobileEndPlace.innerText = endPlace
        $mobileResult.innerText = `${amount + options_price + payStartFromOtherPlace + payEndFromOtherPlace + payStartOnUnWorkTime + payEndOnUnWorkTime} руб.`

        $items.appendChild($datesRowClone)
        $items.appendChild($placesRowClone)
        $items.appendChild($timeRowClone)
        $items.appendChild($summaryRowClone)
    }

    function getData() {

        return {
            options_price,
            option_buster,
            option_buster_price,
            option_childseat,
            option_childseat_price,
            option_phoneholder,
            option_phoneholder_price,
            rent_price,
            rent_total_price,
            rent_days,
            rent_end,
            rent_start,
            take_price,
            return_price,
            take_time_price,
            return_time_price,
        }
    }

    function addItem({$wrapper, title, value}) {
        const $itemClone = document.importNode($itemTemplate.content, true)
        const $title = $itemClone.querySelector(`[${dataPrefix}-title]`)
        const $value = $itemClone.querySelector(`[${dataPrefix}-value]`)
        $title.textContent = title
        $value.textContent = value
        $wrapper.querySelector(`[${dataPrefix}-row]`).appendChild($itemClone)
    }

    this.setData = setData;

    this.getData = getData;
}

function getCountDaysToGetRate(time_pays, duration) {
    let isNaturalDay = time_pays.find(item => +duration.minutes >= +item.from_min);

    if (duration.days === 0) {
        return 1
    }

    if (isNaturalDay) {
        return duration.days + 1
    }

    return duration.days
}

function getDayRate(ratePrices, countDaysToCalc) {
    const actualRatePrices = ratePrices.filter((price) => price.min_days <= countDaysToCalc)
    return actualRatePrices[actualRatePrices.length - 1].price
}

function calcAmount(dayRate, duration, fines) {
    if (duration.days === 0) {
        return dayRate
    }

    const actualFines = fines.filter((fine) => fine.from_min <= duration.minutes)
    const finePercent = actualFines.length > 0 ? actualFines[actualFines.length - 1].percent : 0;
    return dayRate * duration.days + (dayRate / 100 * finePercent)
}

function getPriceForTime(startTime, intervals) {
    for (let interval of intervals) {
        // Преобразуем строку времени в минуты для сравнения
        const startMinutes = toMinutes(interval.start);
        const endMinutes = toMinutes(interval.end);
        const currentMinutes = toMinutes(startTime);

        if (startMinutes <= endMinutes) {
            if (currentMinutes >= startMinutes && currentMinutes <= endMinutes) {
                return interval.price;
            }
        } else {
            // Этот блок нужен для интервалов, которые пересекают полночь (например, с 22:01 до 06:00)
            if (currentMinutes >= startMinutes || currentMinutes <= endMinutes) {
                return interval.price;
            }
        }
    }
    return 0; // Если интервал не найден, возвращаем 0
}

function toMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
}

function convertDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${day}.${month}.${year}`;
}

function declineDays(num) {
    const mod100 = num % 100;
    if (mod100 >= 11 && mod100 <= 14) return "дней";
    switch (num % 10) {
        case 1:
            return "день";
        case 2:
        case 3:
        case 4:
            return "дня";
        default:
            return "дней";
    }
}
